<script src="app.component.ts"></script>
<div class="ttloader" *ngIf="this.common.getFullPageLoader()">
  <span class="loader_spinner">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
  </span>
</div>
<app-header></app-header>
<ng-template #chatCallKit let-payload>
  <h3 mat-dialog-title class="tool-kit-heading"><i class="fa fa-comment-o"></i> Chat Request</h3>
  <div mat-dialog-content>
    <div class="offline-astrologer">
      <img (error)="changeSource($event)" src="{{payload.userPic}}" alt="Astrologer" class="img img-responsive">
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
          <h6 class="name_astrologers_request" *ngIf="payload.userName"><strong>{{payload.userName}}</strong></h6>
          <p class="chat_request_accept">Please accept chat request</p>
      </div>
    </div>
  </div>
  <mat-dialog-actions class="justify-content-evenly">
    <button mat-button mat-dialog-close class="btn_cht chat_request_reject_btn" (click)="rejectChat(payload)">
      Reject</button>
    <button mat-button class="btn_cht chat_request_accept_btn" [mat-dialog-close]="true"
						(click)="checkLocationAndAccept(payload, 'chat')">Accept</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #videoCallKit let-payload>
  <h3 mat-dialog-title class="tool-kit-heading"><i class="fa fa-play-circle"></i> Video Call Request</h3>
  <div mat-dialog-content>
    <div class="offline-astrologer">
      <img (error)="changeSource($event)" src="{{payload.userPic}}" alt="Astrologer" class="img img-responsive">
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="text-center">
          <h6 class="name_astrologers_request"><strong>{{payload.userName}}</strong></h6>
        </div>
        <div class="text-center">
          <p class="chat_request_accept">Please accept video call request</p>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center">
    <button mat-dialog-close class="btn_cht busy-status chat_request_reject_btn" (click)="rejectVideoCall(payload)">Reject</button>&nbsp;&nbsp;
    <button class="btn_cht online-status chat_request_accept_btn" [mat-dialog-close]="true" (click)="checkLocationAndAccept(payload, 'call')">Accept</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #showLocation let-data>
	<h5 *ngIf="data.heading" mat-dialog-title>{{data.heading}}</h5>
	<div [class.text-bold]="!data.warning" mat-dialog-content>{{data.message}}</div>
	<mat-dialog-actions class="justify-content-end">
		<button class="btn_cht cancelBtn" mat-dialog-close>{{'Cancel'}}</button>
		<button class="btn_cht deleteBtn" mat-dialog-close>{{'Ok'}}</button>
	</mat-dialog-actions>
</ng-template>
<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
