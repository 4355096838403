import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuardService} from './core/guards/auth.guard.service';
import {NoAuthGuardService} from './core/guards/no-auth.guard.service';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./module/registration/registration.module').then(m => m.RegistrationModule),
		canActivate: [NoAuthGuardService]
	},
	{
		path: '',
		loadChildren: () => import('./module/landing/landing.module').then(m => m.LandingModule),
		canActivate: [AuthGuardService]
	},
	{
		path: '',
		loadChildren: () => import('./module/astrologer-profile/astrologer-profile.module').then(m => m.AstrologerProfileModule),
		canActivate: [AuthGuardService]
	},
	{
		path: '',
		loadChildren: () => import('./module/astrologer-support/astrologer-support.module').then(m =>
			m.AstrologerSupportModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'gallery',
		loadChildren: () => import('./module/galleries/galleries.module').then(m => m.GalleriesModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'in-mail',
		loadChildren: () => import('./module/inmail-history/inmail-history.module').then(m => m.InmailHistoryModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'astrologer-interview',
		loadChildren: () => import('./module/astrologer-interview/astrologer-interview.module').then(m =>
			m.AstrologerInterviewModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'chat-with-user',
		loadChildren: () => import('./module/chat-with-user/chat-with-user.module').then(m =>
			m.ChatWithUserModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'call-to-user/:callId',
		loadChildren: () => import('./module/call-to-user/call-to-user.module').then(m =>
			m.CallToUserModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'my-followers',
		loadChildren: () => import('./module/my-followers/my-followers.module').then(m =>
			m.MyFollowersModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'loyal-club-membership',
		loadChildren: () => import('./module/loyal-club-membership/loyal-club-membership.module').then(m => m.LoyalClubMembershipModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'chat-history',
		loadChildren: () => import('./module/chat-history/chat-history.module').then(m =>
			m.ChatHistoryModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'call-history',
		loadChildren: () => import('./module/call-history/call-history.module').then(m =>
			m.CallHistoryModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'astromall-history',
		loadChildren: () => import('./module/astromall-history/astromall-history.module').then(m =>
			m.AstromallHistoryModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'report-history',
		loadChildren: () => import('./module/report-history/report-history.module').then(m =>
			m.ReportHistoryModule),
		canActivate: [AuthGuardService]
	},
	/*{
		path: 'earning-history',
		loadChildren: () => import('./module/earning-history/earning-history.module').then(m =>
			m.EarningHistoryModule),
		canActivate: [AuthGuardService]
	},*/
	{
		path: 'offers',
		loadChildren: () => import('./module/offer/offer.module').then(m =>
			m.OfferModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'wait-list',
		loadChildren: () => import('./module/waitlist/waitlist.module').then(m =>
			m.WaitlistModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'wallet',
		loadChildren: () => import('./module/wallet/wallet.module').then(m =>
			m.WalletModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'remedies',
		loadChildren: () => import('./module/suggested-remedies/suggested-remedies.module').then(m =>
			m.SuggestedRemediesModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'live-event',
		loadChildren: () => import('./module/live-event-astrologer/live-event-astrologer.module').then(m =>
			m.LiveEventAstrologerModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'my-reviews',
		loadChildren: () => import('./module/my-reviews/my-reviews.module').then(m => m.MyReviewsModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'boost-history',
		loadChildren: () => import('./module/boost-history/boost-history.module').then(m => m.BoostHistoryModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'mandatory-warnings',
		loadChildren: () => import('./module/warnings/warnings.module').then(m => m.WarningsModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'download-app',
		loadChildren: () => import('./module/download-aap/download-aap.module').then(m => m.DownloadAapModule),
		canActivate: [AuthGuardService]
	},
	{
		path: '**', redirectTo: '/'
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			preloadingStrategy: PreloadAllModules,
			relativeLinkResolution: 'legacy'
		})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
