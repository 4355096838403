import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {AppConstant} from './constant';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {HttpClient, HttpHeaders} from '@angular/common/http';


class LocalStorage implements Storage {
	[name: string]: any;

	readonly length: number;

	clear(): void {
	}

	getItem(key: string): string | null {
		return undefined;
	}

	key(index: number): string | null {
		return undefined;
	}

	removeItem(key: string): void {
	}

	setItem(key: string, value: string): void {
	}
}

@Injectable()
export class CommonService {

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(DOCUMENT) private document: Document,
		private toastr: ToastrService,
		private http: HttpClient) {
		this.storage = new LocalStorage();
		this.sessStorage = sessionStorage;
		this.isBrowser = isPlatformBrowser(platformId);
		if (this.isBrowser) {
			this.storage = localStorage;
		}
		const token = !!(this.getToken());
		if (token) {
			this.isLogin.next(true);
		} else {
			this.isLogin.next(false);
		}
	}

	private storage: Storage;
	private sessStorage;
	private isBrowser = false;
	private urlHistory: string;
	isBrowserService = new BehaviorSubject<boolean>(null);
	base64: any;
	manualFullPageLoader = false;
	appId = 3;
	APP_ID_WEB = 4;
	APP_ID_WEB_MOBILE = 3;
	businessId = 1;
	interviewerBusinessID = 5;
	astroId: number;
	appVersion = '1.0';
	languageId = 1;
	serviceId = 3;
	QUERY_SERVICEID = 1;
	REPORT_SERVICEID = 2;
	MALL_SERVICEID = 5;
	CALL_SERVICEID = 3;
	CHAT_SERVICEID = 4;
	basicErr = 'Oops! Something went wrong, please try again later.';
	private isLogin = new BehaviorSubject<boolean>(null);
	private isBlockedFeedback = new BehaviorSubject<boolean>(null);

	fileUploadApi = AppConstant.apiUrl + AppConstant.fileUploadApi;

	setLoginValue(newValue): void {
		this.isLogin.next(newValue);
	}

	getLoginValue(): Observable<boolean> {
		return this.isLogin;
	}

	setFeedBackValue(newValue): void {
		this.isBlockedFeedback.next(newValue);
	}

	getFeedBackValue(): Observable<boolean> {
		return this.isBlockedFeedback;
	}

	setUrlHistory = (url) => {
		this.urlHistory = url;
	}

	getUrlHistory = () => {
		return (typeof this.urlHistory !== 'undefined') ? this.urlHistory : '/';
	}

	getServiceId(service?: string): number {
		if (service === 'call') {
			return this.CALL_SERVICEID;
		} else if (service === 'chat') {
			return this.CHAT_SERVICEID;
		} else if (service === 'report') {
			return this.REPORT_SERVICEID;
		} else if (service === 'mall') {
			return this.MALL_SERVICEID;
		} else if (service === 'query') {
			return this.QUERY_SERVICEID;
		} else {
			return this.serviceId;
		}
	}

	getAppId() {
		if (this.checkDeviceIsMobile()) {
			return this.APP_ID_WEB_MOBILE;
		} else {
			return this.APP_ID_WEB;
		}
	}

	getLanguageId() {
		return this.languageId;
	}

	getBusinessId(interviewer = false) {
		if (interviewer) {
			return this.interviewerBusinessID;
		} else {
			return this.businessId;
		}
	}

	getAppVersion() {
		return this.appVersion;
	}

	getItem(name): string {
		return this.storage.getItem(name);
	}

	setItem(name, value: string): void {
		this.storage.setItem(name, value);
	}

	setSessionItem(name, value: string): void {
		this.sessStorage.setItem(name, value);
	}

	getSessionItem(name): string {
		return this.sessStorage.getItem(name);
	}

	getToken(): string {
		return this.storage.getItem(AppConstant.token);
	}

	setToken(token: string): void {
		this.storage.setItem(AppConstant.token, token);
	}

	setIp(ipAddress: string): void {
		this.storage.setItem('ip', ipAddress);
	}

	getIp(): string {
		return this.storage.getItem('ip');
	}

	setAstroData(data): void {
		if (data.isForeign) {
			this.storage.setItem('timeZone', 'foreign');
		} else {
			this.setLocalTimeZone();
		}
		this.storage.setItem('astroData', JSON.stringify(data));
	}

	getAstroData(key = null): any {
		const jsonData = this.storage.getItem('astroData');
		if (jsonData && jsonData != '') {
			const astroData = JSON.parse(jsonData);
			if (key) {
				return (astroData && astroData[key]) ? astroData[key] : null;
			}
			return astroData;
		}
		return jsonData;
	}

	logout() {
		this.setLoginValue(false);
		this.setLocalTimeZone();
		this.storage.removeItem(AppConstant.token);
		this.storage.removeItem('astroData');
		this.storage.removeItem('isToTakeVerifiedNo');
		this.storage.removeItem('hasLanguage');
		this.storage.removeItem('isToTakeVerifiedNo_firebase');
		this.storage.removeItem('chatCallTip');
		this.storage.removeItem('chatCall2ndTip');
		this.storage.removeItem('fireBaseToken');
		this.storage.removeItem('npsLevel');
		this.storage.removeItem('isDeleted');
		this.storage.removeItem('isOpenForSupportWithSdOff');
		this.storage.removeItem('blockCeoFeedback');
		this.storage.removeItem('blockSupportTicket');
		this.storage.removeItem('showSite');
		this.storage.removeItem('showLiveDonationPrice');
	}

	showFullPageLoader() {
		// if (!this.manualFullPageLoader) {
		this.manualFullPageLoader = true;
		// }
	}

	hideFullPageLoader() {
		// if (this.manualFullPageLoader) {
		this.manualFullPageLoader = false;
		// }

	}

	getFullPageLoader() {
		return this.manualFullPageLoader;
	}

	checkDeviceIsBrowser = (): boolean => {
		return this.isBrowser;
	}

	toastrInvoke(type: string, msg: string = '', time = 10000) {
		if (this.isBrowser) {
			if (type === 'error') {
				if (msg) {
					return this.toastr.error(msg, '', {timeOut: time});
				} else {
					return this.toastr.error(this.basicErr, '', {timeOut: time});
				}
			} else if (type === 'success') {
				this.toastr.success(msg, '', {timeOut: time});
			} else if (type === 'warning') {
				this.toastr.warning(msg, '', {timeOut: time});
			} else if (type === 'info') {
				this.toastr.info(msg, '', {timeOut: time});
			}
		}
	}

	checkDeviceIsMobile = () => {
		if (this.isBrowser) {
			const ua = navigator.userAgent;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
				return true;
			} else if (/Chrome/i.test(ua)) {
				return false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	timeZone = () => {
		return this.getItem('timeZone');
	}

	setApiTimeZone = (isForeign: boolean) => {
		if (isForeign) {
			this.setItem('timeZone', 'foreign');
		} else {
			this.setItem('timeZone', 'Asia/Kolkata');
		}
	}


	async setLocalTimeZone() {
		const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (time == 'Asia/Calcutta' || time == 'Asia/Kolkata') {
			this.setItem('timeZone', 'Asia/Kolkata');
		} else {
			this.setItem('timeZone', 'foreign');
		}
	}

	public fileUpload = (body) => {
		const token = this.getToken();
		const header = {
			headers: new HttpHeaders()
				.set('Authorization', 'Bearer ' + (token).toString())
				.set('id', (this.getAstroData('id')).toString())
		};
		return this.http.post(this.fileUploadApi, body, header);
	}

	public getMobileCode = () => {
		return [
			{
				name: 'Afghanistan',
				dial_code: '+93',
				code: 'AF'
			},
			{
				name: 'Aland Islands',
				dial_code: '+358',
				code: 'AX'
			},
			{
				name: 'Albania',
				dial_code: '+355',
				code: 'AL'
			},
			{
				name: 'Algeria',
				dial_code: '+213',
				code: 'DZ'
			},
			{
				name: 'AmericanSamoa',
				dial_code: '+1 684',
				code: 'AS'
			},
			{
				name: 'Andorra',
				dial_code: '+376',
				code: 'AD'
			},
			{
				name: 'Angola',
				dial_code: '+244',
				code: 'AO'
			},
			{
				name: 'Anguilla',
				dial_code: '+1 264',
				code: 'AI'
			},
			{
				name: 'Antarctica',
				dial_code: '+672',
				code: 'AQ'
			},
			{
				name: 'Antigua and Barbuda',
				dial_code: '+1268',
				code: 'AG'
			},
			{
				name: 'Argentina',
				dial_code: '+54',
				code: 'AR'
			},
			{
				name: 'Armenia',
				dial_code: '+374',
				code: 'AM'
			},
			{
				name: 'Aruba',
				dial_code: '+297',
				code: 'AW'
			},
			{
				name: 'Australia',
				dial_code: '+61',
				code: 'AU'
			},
			{
				name: 'Austria',
				dial_code: '+43',
				code: 'AT'
			},
			{
				name: 'Azerbaijan',
				dial_code: '+994',
				code: 'AZ'
			},
			{
				name: 'Bahamas',
				dial_code: '+1 242',
				code: 'BS'
			},
			{
				name: 'Bahrain',
				dial_code: '+973',
				code: 'BH'
			},
			{
				name: 'Bangladesh',
				dial_code: '+880',
				code: 'BD'
			},
			{
				name: 'Barbados',
				dial_code: '+1 246',
				code: 'BB'
			},
			{
				name: 'Belarus',
				dial_code: '+375',
				code: 'BY'
			},
			{
				name: 'Belgium',
				dial_code: '+32',
				code: 'BE'
			},
			{
				name: 'Belize',
				dial_code: '+501',
				code: 'BZ'
			},
			{
				name: 'Benin',
				dial_code: '+229',
				code: 'BJ'
			},
			{
				name: 'Bermuda',
				dial_code: '+1 441',
				code: 'BM'
			},
			{
				name: 'Bhutan',
				dial_code: '+975',
				code: 'BT'
			},
			{
				name: 'Bolivia, Plurinational State of',
				dial_code: '+591',
				code: 'BO'
			},
			{
				name: 'Bosnia and Herzegovina',
				dial_code: '+387',
				code: 'BA'
			},
			{
				name: 'Botswana',
				dial_code: '+267',
				code: 'BW'
			},
			{
				name: 'Brazil',
				dial_code: '+55',
				code: 'BR'
			},
			{
				name: 'British Indian Ocean Territory',
				dial_code: '+246',
				code: 'IO'
			},
			{
				name: 'Brunei Darussalam',
				dial_code: '+673',
				code: 'BN'
			},
			{
				name: 'Bulgaria',
				dial_code: '+359',
				code: 'BG'
			},
			{
				name: 'Burkina Faso',
				dial_code: '+226',
				code: 'BF'
			},
			{
				name: 'Burundi',
				dial_code: '+257',
				code: 'BI'
			},
			{
				name: 'Cambodia',
				dial_code: '+855',
				code: 'KH'
			},
			{
				name: 'Cameroon',
				dial_code: '+237',
				code: 'CM'
			},
			{
				name: 'Canada',
				dial_code: '+1',
				code: 'CA'
			},
			{
				name: 'Cape Verde',
				dial_code: '+238',
				code: 'CV'
			},
			{
				name: 'Cayman Islands',
				dial_code: '+ 345',
				code: 'KY'
			},
			{
				name: 'Central African Republic',
				dial_code: '+236',
				code: 'CF'
			},
			{
				name: 'Chad',
				dial_code: '+235',
				code: 'TD'
			},
			{
				name: 'Chile',
				dial_code: '+56',
				code: 'CL'
			},
			{
				name: 'China',
				dial_code: '+86',
				code: 'CN'
			},
			{
				name: 'Christmas Island',
				dial_code: '+61',
				code: 'CX'
			},
			{
				name: 'Cocos (Keeling) Islands',
				dial_code: '+61',
				code: 'CC'
			},
			{
				name: 'Colombia',
				dial_code: '+57',
				code: 'CO'
			},
			{
				name: 'Comoros',
				dial_code: '+269',
				code: 'KM'
			},
			{
				name: 'Congo',
				dial_code: '+242',
				code: 'CG'
			},
			{
				name: 'Congo, The Democratic Republic of the Congo',
				dial_code: '+243',
				code: 'CD'
			},
			{
				name: 'Cook Islands',
				dial_code: '+682',
				code: 'CK'
			},
			{
				name: 'Costa Rica',
				dial_code: '+506',
				code: 'CR'
			},
			{
				name: 'Cote d\'Ivoire',
				dial_code: '+225',
				code: 'CI'
			},
			{
				name: 'Croatia',
				dial_code: '+385',
				code: 'HR'
			},
			{
				name: 'Cuba',
				dial_code: '+53',
				code: 'CU'
			},
			{
				name: 'Cyprus',
				dial_code: '+357',
				code: 'CY'
			},
			{
				name: 'Czech Republic',
				dial_code: '+420',
				code: 'CZ'
			},
			{
				name: 'Denmark',
				dial_code: '+45',
				code: 'DK'
			},
			{
				name: 'Djibouti',
				dial_code: '+253',
				code: 'DJ'
			},
			{
				name: 'Dominica',
				dial_code: '+1 767',
				code: 'DM'
			},
			{
				name: 'Dominican Republic',
				dial_code: '+1 849',
				code: 'DO'
			},
			{
				name: 'Ecuador',
				dial_code: '+593',
				code: 'EC'
			},
			{
				name: 'Egypt',
				dial_code: '+20',
				code: 'EG'
			},
			{
				name: 'El Salvador',
				dial_code: '+503',
				code: 'SV'
			},
			{
				name: 'Equatorial Guinea',
				dial_code: '+240',
				code: 'GQ'
			},
			{
				name: 'Eritrea',
				dial_code: '+291',
				code: 'ER'
			},
			{
				name: 'Estonia',
				dial_code: '+372',
				code: 'EE'
			},
			{
				name: 'Ethiopia',
				dial_code: '+251',
				code: 'ET'
			},
			{
				name: 'Falkland Islands (Malvinas)',
				dial_code: '+500',
				code: 'FK'
			},
			{
				name: 'Faroe Islands',
				dial_code: '+298',
				code: 'FO'
			},
			{
				name: 'Fiji',
				dial_code: '+679',
				code: 'FJ'
			},
			{
				name: 'Finland',
				dial_code: '+358',
				code: 'FI'
			},
			{
				name: 'France',
				dial_code: '+33',
				code: 'FR'
			},
			{
				name: 'French Guiana',
				dial_code: '+594',
				code: 'GF'
			},
			{
				name: 'French Polynesia',
				dial_code: '+689',
				code: 'PF'
			},
			{
				name: 'Gabon',
				dial_code: '+241',
				code: 'GA'
			},
			{
				name: 'Gambia',
				dial_code: '+220',
				code: 'GM'
			},
			{
				name: 'Georgia',
				dial_code: '+995',
				code: 'GE'
			},
			{
				name: 'Germany',
				dial_code: '+49',
				code: 'DE'
			},
			{
				name: 'Ghana',
				dial_code: '+233',
				code: 'GH'
			},
			{
				name: 'Gibraltar',
				dial_code: '+350',
				code: 'GI'
			},
			{
				name: 'Greece',
				dial_code: '+30',
				code: 'GR'
			},
			{
				name: 'Greenland',
				dial_code: '+299',
				code: 'GL'
			},
			{
				name: 'Grenada',
				dial_code: '+1 473',
				code: 'GD'
			},
			{
				name: 'Guadeloupe',
				dial_code: '+590',
				code: 'GP'
			},
			{
				name: 'Guam',
				dial_code: '+1 671',
				code: 'GU'
			},
			{
				name: 'Guatemala',
				dial_code: '+502',
				code: 'GT'
			},
			{
				name: 'Guernsey',
				dial_code: '+44',
				code: 'GG'
			},
			{
				name: 'Guinea',
				dial_code: '+224',
				code: 'GN'
			},
			{
				name: 'Guinea-Bissau',
				dial_code: '+245',
				code: 'GW'
			},
			{
				name: 'Guyana',
				dial_code: '+595',
				code: 'GY'
			},
			{
				name: 'Haiti',
				dial_code: '+509',
				code: 'HT'
			},
			{
				name: 'Holy See (Vatican City State)',
				dial_code: '+379',
				code: 'VA'
			},
			{
				name: 'Honduras',
				dial_code: '+504',
				code: 'HN'
			},
			{
				name: 'Hong Kong',
				dial_code: '+852',
				code: 'HK'
			},
			{
				name: 'Hungary',
				dial_code: '+36',
				code: 'HU'
			},
			{
				name: 'Iceland',
				dial_code: '+354',
				code: 'IS'
			},
			{
				name: 'India',
				dial_code: '+91',
				code: 'IN'
			},
			{
				name: 'Indonesia',
				dial_code: '+62',
				code: 'ID'
			},
			{
				name: 'Iran, Islamic Republic of Persian Gulf',
				dial_code: '+98',
				code: 'IR'
			},
			{
				name: 'Iraq',
				dial_code: '+964',
				code: 'IQ'
			},
			{
				name: 'Ireland',
				dial_code: '+353',
				code: 'IE'
			},
			{
				name: 'Isle of Man',
				dial_code: '+44',
				code: 'IM'
			},
			{
				name: 'Israel',
				dial_code: '+972',
				code: 'IL'
			},
			{
				name: 'Italy',
				dial_code: '+39',
				code: 'IT'
			},
			{
				name: 'Jamaica',
				dial_code: '+1 876',
				code: 'JM'
			},
			{
				name: 'Japan',
				dial_code: '+81',
				code: 'JP'
			},
			{
				name: 'Jersey',
				dial_code: '+44',
				code: 'JE'
			},
			{
				name: 'Jordan',
				dial_code: '+962',
				code: 'JO'
			},
			{
				name: 'Kazakhstan',
				dial_code: '+7 7',
				code: 'KZ'
			},
			{
				name: 'Kenya',
				dial_code: '+254',
				code: 'KE'
			},
			{
				name: 'Kiribati',
				dial_code: '+686',
				code: 'KI'
			},
			{
				name: 'Korea, Democratic People\'s Republic of Korea',
				dial_code: '+850',
				code: 'KP'
			},
			{
				name: 'Korea, Republic of South Korea',
				dial_code: '+82',
				code: 'KR'
			},
			{
				name: 'Kuwait',
				dial_code: '+965',
				code: 'KW'
			},
			{
				name: 'Kyrgyzstan',
				dial_code: '+996',
				code: 'KG'
			},
			{
				name: 'Laos',
				dial_code: '+856',
				code: 'LA'
			},
			{
				name: 'Latvia',
				dial_code: '+371',
				code: 'LV'
			},
			{
				name: 'Lebanon',
				dial_code: '+961',
				code: 'LB'
			},
			{
				name: 'Lesotho',
				dial_code: '+266',
				code: 'LS'
			},
			{
				name: 'Liberia',
				dial_code: '+231',
				code: 'LR'
			},
			{
				name: 'Libyan Arab Jamahiriya',
				dial_code: '+218',
				code: 'LY'
			},
			{
				name: 'Liechtenstein',
				dial_code: '+423',
				code: 'LI'
			},
			{
				name: 'Lithuania',
				dial_code: '+370',
				code: 'LT'
			},
			{
				name: 'Luxembourg',
				dial_code: '+352',
				code: 'LU'
			},
			{
				name: 'Macao',
				dial_code: '+853',
				code: 'MO'
			},
			{
				name: 'Macedonia',
				dial_code: '+389',
				code: 'MK'
			},
			{
				name: 'Madagascar',
				dial_code: '+261',
				code: 'MG'
			},
			{
				name: 'Malawi',
				dial_code: '+265',
				code: 'MW'
			},
			{
				name: 'Malaysia',
				dial_code: '+60',
				code: 'MY'
			},
			{
				name: 'Maldives',
				dial_code: '+960',
				code: 'MV'
			},
			{
				name: 'Mali',
				dial_code: '+223',
				code: 'ML'
			},
			{
				name: 'Malta',
				dial_code: '+356',
				code: 'MT'
			},
			{
				name: 'Marshall Islands',
				dial_code: '+692',
				code: 'MH'
			},
			{
				name: 'Martinique',
				dial_code: '+596',
				code: 'MQ'
			},
			{
				name: 'Mauritania',
				dial_code: '+222',
				code: 'MR'
			},
			{
				name: 'Mauritius',
				dial_code: '+230',
				code: 'MU'
			},
			{
				name: 'Mayotte',
				dial_code: '+262',
				code: 'YT'
			},
			{
				name: 'Mexico',
				dial_code: '+52',
				code: 'MX'
			},
			{
				name: 'Micronesia, Federated States of Micronesia',
				dial_code: '+691',
				code: 'FM'
			},
			{
				name: 'Moldova',
				dial_code: '+373',
				code: 'MD'
			},
			{
				name: 'Monaco',
				dial_code: '+377',
				code: 'MC'
			},
			{
				name: 'Mongolia',
				dial_code: '+976',
				code: 'MN'
			},
			{
				name: 'Montenegro',
				dial_code: '+382',
				code: 'ME'
			},
			{
				name: 'Montserrat',
				dial_code: '+1664',
				code: 'MS'
			},
			{
				name: 'Morocco',
				dial_code: '+212',
				code: 'MA'
			},
			{
				name: 'Mozambique',
				dial_code: '+258',
				code: 'MZ'
			},
			{
				name: 'Myanmar',
				dial_code: '+95',
				code: 'MM'
			},
			{
				name: 'Namibia',
				dial_code: '+264',
				code: 'NA'
			},
			{
				name: 'Nauru',
				dial_code: '+674',
				code: 'NR'
			},
			{
				name: 'Nepal',
				dial_code: '+977',
				code: 'NP'
			},
			{
				name: 'Netherlands',
				dial_code: '+31',
				code: 'NL'
			},
			{
				name: 'Netherlands Antilles',
				dial_code: '+599',
				code: 'AN'
			},
			{
				name: 'New Caledonia',
				dial_code: '+687',
				code: 'NC'
			},
			{
				name: 'New Zealand',
				dial_code: '+64',
				code: 'NZ'
			},
			{
				name: 'Nicaragua',
				dial_code: '+505',
				code: 'NI'
			},
			{
				name: 'Niger',
				dial_code: '+227',
				code: 'NE'
			},
			{
				name: 'Nigeria',
				dial_code: '+234',
				code: 'NG'
			},
			{
				name: 'Niue',
				dial_code: '+683',
				code: 'NU'
			},
			{
				name: 'Norfolk Island',
				dial_code: '+672',
				code: 'NF'
			},
			{
				name: 'Northern Mariana Islands',
				dial_code: '+1 670',
				code: 'MP'
			},
			{
				name: 'Norway',
				dial_code: '+47',
				code: 'NO'
			},
			{
				name: 'Oman',
				dial_code: '+968',
				code: 'OM'
			},
			{
				name: 'Pakistan',
				dial_code: '+92',
				code: 'PK'
			},
			{
				name: 'Palau',
				dial_code: '+680',
				code: 'PW'
			},
			{
				name: 'Palestinian Territory, Occupied',
				dial_code: '+970',
				code: 'PS'
			},
			{
				name: 'Panama',
				dial_code: '+507',
				code: 'PA'
			},
			{
				name: 'Papua New Guinea',
				dial_code: '+675',
				code: 'PG'
			},
			{
				name: 'Paraguay',
				dial_code: '+595',
				code: 'PY'
			},
			{
				name: 'Peru',
				dial_code: '+51',
				code: 'PE'
			},
			{
				name: 'Philippines',
				dial_code: '+63',
				code: 'PH'
			},
			{
				name: 'Pitcairn',
				dial_code: '+872',
				code: 'PN'
			},
			{
				name: 'Poland',
				dial_code: '+48',
				code: 'PL'
			},
			{
				name: 'Portugal',
				dial_code: '+351',
				code: 'PT'
			},
			{
				name: 'Puerto Rico',
				dial_code: '+1 939',
				code: 'PR'
			},
			{
				name: 'Qatar',
				dial_code: '+974',
				code: 'QA'
			},
			{
				name: 'Romania',
				dial_code: '+40',
				code: 'RO'
			},
			{
				name: 'Russia',
				dial_code: '+7',
				code: 'RU'
			},
			{
				name: 'Rwanda',
				dial_code: '+250',
				code: 'RW'
			},
			{
				name: 'Reunion',
				dial_code: '+262',
				code: 'RE'
			},
			{
				name: 'Saint Barthelemy',
				dial_code: '+590',
				code: 'BL'
			},
			{
				name: 'Saint Helena, Ascension and Tristan Da Cunha',
				dial_code: '+290',
				code: 'SH'
			},
			{
				name: 'Saint Kitts and Nevis',
				dial_code: '+1 869',
				code: 'KN'
			},
			{
				name: 'Saint Lucia',
				dial_code: '+1 758',
				code: 'LC'
			},
			{
				name: 'Saint Martin',
				dial_code: '+590',
				code: 'MF'
			},
			{
				name: 'Saint Pierre and Miquelon',
				dial_code: '+508',
				code: 'PM'
			},
			{
				name: 'Saint Vincent and the Grenadines',
				dial_code: '+1 784',
				code: 'VC'
			},
			{
				name: 'Samoa',
				dial_code: '+685',
				code: 'WS'
			},
			{
				name: 'San Marino',
				dial_code: '+378',
				code: 'SM'
			},
			{
				name: 'Sao Tome and Principe',
				dial_code: '+239',
				code: 'ST'
			},
			{
				name: 'Saudi Arabia',
				dial_code: '+966',
				code: 'SA'
			},
			{
				name: 'Senegal',
				dial_code: '+221',
				code: 'SN'
			},
			{
				name: 'Serbia',
				dial_code: '+381',
				code: 'RS'
			},
			{
				name: 'Seychelles',
				dial_code: '+248',
				code: 'SC'
			},
			{
				name: 'Sierra Leone',
				dial_code: '+232',
				code: 'SL'
			},
			{
				name: 'Singapore',
				dial_code: '+65',
				code: 'SG'
			},
			{
				name: 'Slovakia',
				dial_code: '+421',
				code: 'SK'
			},
			{
				name: 'Slovenia',
				dial_code: '+386',
				code: 'SI'
			},
			{
				name: 'Solomon Islands',
				dial_code: '+677',
				code: 'SB'
			},
			{
				name: 'Somalia',
				dial_code: '+252',
				code: 'SO'
			},
			{
				name: 'South Africa',
				dial_code: '+27',
				code: 'ZA'
			},
			{
				name: 'South Georgia and the South Sandwich Islands',
				dial_code: '+500',
				code: 'GS'
			},
			{
				name: 'Spain',
				dial_code: '+34',
				code: 'ES'
			},
			{
				name: 'Sri Lanka',
				dial_code: '+94',
				code: 'LK'
			},
			{
				name: 'Sudan',
				dial_code: '+249',
				code: 'SD'
			},
			{
				name: 'Suriname',
				dial_code: '+597',
				code: 'SR'
			},
			{
				name: 'Svalbard and Jan Mayen',
				dial_code: '+47',
				code: 'SJ'
			},
			{
				name: 'Swaziland',
				dial_code: '+268',
				code: 'SZ'
			},
			{
				name: 'Sweden',
				dial_code: '+46',
				code: 'SE'
			},
			{
				name: 'Switzerland',
				dial_code: '+41',
				code: 'CH'
			},
			{
				name: 'Syrian Arab Republic',
				dial_code: '+963',
				code: 'SY'
			},
			{
				name: 'Taiwan',
				dial_code: '+886',
				code: 'TW'
			},
			{
				name: 'Tajikistan',
				dial_code: '+992',
				code: 'TJ'
			},
			{
				name: 'Tanzania, United Republic of Tanzania',
				dial_code: '+255',
				code: 'TZ'
			},
			{
				name: 'Thailand',
				dial_code: '+66',
				code: 'TH'
			},
			{
				name: 'Timor-Leste',
				dial_code: '+670',
				code: 'TL'
			},
			{
				name: 'Togo',
				dial_code: '+228',
				code: 'TG'
			},
			{
				name: 'Tokelau',
				dial_code: '+690',
				code: 'TK'
			},
			{
				name: 'Tonga',
				dial_code: '+676',
				code: 'TO'
			},
			{
				name: 'Trinidad and Tobago',
				dial_code: '+1 868',
				code: 'TT'
			},
			{
				name: 'Tunisia',
				dial_code: '+216',
				code: 'TN'
			},
			{
				name: 'Turkey',
				dial_code: '+90',
				code: 'TR'
			},
			{
				name: 'Turkmenistan',
				dial_code: '+993',
				code: 'TM'
			},
			{
				name: 'Turks and Caicos Islands',
				dial_code: '+1 649',
				code: 'TC'
			},
			{
				name: 'Tuvalu',
				dial_code: '+688',
				code: 'TV'
			},
			{
				name: 'Uganda',
				dial_code: '+256',
				code: 'UG'
			},
			{
				name: 'Ukraine',
				dial_code: '+380',
				code: 'UA'
			},
			{
				name: 'United Arab Emirates',
				dial_code: '+971',
				code: 'AE'
			},
			{
				name: 'United Kingdom',
				dial_code: '+44',
				code: 'GB'
			},
			{
				name: 'United States',
				dial_code: '+1',
				code: 'US'
			},
			{
				name: 'Uruguay',
				dial_code: '+598',
				code: 'UY'
			},
			{
				name: 'Uzbekistan',
				dial_code: '+998',
				code: 'UZ'
			},
			{
				name: 'Vanuatu',
				dial_code: '+678',
				code: 'VU'
			},
			{
				name: 'Venezuela, Bolivarian Republic of Venezuela',
				dial_code: '+58',
				code: 'VE'
			},
			{
				name: 'Vietnam',
				dial_code: '+84',
				code: 'VN'
			},
			{
				name: 'Virgin Islands, British',
				dial_code: '+1 284',
				code: 'VG'
			},
			{
				name: 'Virgin Islands, U.S.',
				dial_code: '+1 340',
				code: 'VI'
			},
			{
				name: 'Wallis and Futuna',
				dial_code: '+681',
				code: 'WF'
			},
			{
				name: 'Yemen',
				dial_code: '+967',
				code: 'YE'
			},
			{
				name: 'Zambia',
				dial_code: '+260',
				code: 'ZM'
			},
			{
				name: 'Zimbabwe',
				dial_code: '+263',
				code: 'ZW'
			}
		];
	}

	// tslint:disable-next-line:only-arrow-functions
	public getCountries = () => {
		return [
			{value: 'AF', key: 'Afghanistan'}
			, {value: 'AX', key: 'Aland Islands'}
			, {value: 'AL', key: 'Albania'}
			, {value: 'DZ', key: 'Algeria'}
			, {value: 'AS', key: 'American Samoa'}
			, {value: 'AD', key: 'Andorra'}
			, {value: 'AO', key: 'Angola'}
			, {value: 'AI', key: 'Anguilla'}
			, {value: 'AQ', key: 'Antarctica'}
			, {value: 'AG', key: 'Antigua and Barbuda'}
			, {value: 'AR', key: 'Argentina'}
			, {value: 'AM', key: 'Armenia'}
			, {value: 'AW', key: 'Aruba'}
			, {value: 'AU', key: 'Australia'}
			, {value: 'AT', key: 'Austria'}
			, {value: 'AZ', key: 'Azerbaijan'}
			, {value: 'BS', key: 'Bahamas'}
			, {value: 'BH', key: 'Bahrain'}
			, {value: 'BD', key: 'Bangladesh'}
			, {value: 'BB', key: 'Barbados'}
			, {value: 'BY', key: 'Belarus'}
			, {value: 'BE', key: 'Belgium'}
			, {value: 'BZ', key: 'Belize'}
			, {value: 'BJ', key: 'Benin'}
			, {value: 'BM', key: 'Bermuda'}
			, {value: 'BT', key: 'Bhutan'}
			, {value: 'BO', key: 'Bolivia'}
			, {value: 'BQ', key: 'Bonaire'}
			, {value: 'BA', key: 'Bosnia and Herzegovina'}
			, {value: 'BW', key: 'Botswana'}
			, {value: 'BV', key: 'Bouvet Island'}
			, {value: 'BR', key: 'Brazil'}
			, {value: 'IO', key: 'British Indian Ocean Territory'}
			, {value: 'BN', key: 'Brunei Darussalam'}
			, {value: 'BG', key: 'Bulgaria'}
			, {value: 'BF', key: 'Burkina Faso'}
			, {value: 'BI', key: 'Burundi'}
			, {value: 'KH', key: 'Cambodia'}
			, {value: 'CM', key: 'Cameroon'}
			, {value: 'CA', key: 'Canada'}
			, {value: 'CV', key: 'Cape Verde'}
			, {value: 'KY', key: 'Cayman Islands'}
			, {value: 'CF', key: 'Central African Republic'}
			, {value: 'TD', key: 'Chad'}
			, {value: 'CL', key: 'Chile'}
			, {value: 'CN', key: 'China'}
			, {value: 'CX', key: 'Christmas Island'}
			, {value: 'CC', key: 'Cocos (Keeling) Islands'}
			, {value: 'CO', key: 'Colombia'}
			, {value: 'KM', key: 'Comoros'}
			, {value: 'CG', key: 'Congo'}
			, {value: 'CD', key: 'Congo'}
			, {value: 'CK', key: 'Cook Islands'}
			, {value: 'CR', key: 'Costa Rica'}
			, {value: 'CI', key: 'Côte d\'Ivoire'}
			, {value: 'HR', key: 'Croatia'}
			, {value: 'CU', key: 'Cuba'}
			, {value: 'CW', key: 'Curaçao'}
			, {value: 'CY', key: 'Cyprus'}
			, {value: 'CZ', key: 'Czech Republic'}
			, {value: 'DK', key: 'Denmark'}
			, {value: 'DJ', key: 'Djibouti'}
			, {value: 'DM', key: 'Dominica'}
			, {value: 'DO', key: 'Dominican Republic'}
			, {value: 'EC', key: 'Ecuador'}
			, {value: 'EG', key: 'Egypt'}
			, {value: 'SV', key: 'El Salvador'}
			, {value: 'GQ', key: 'Equatorial Guinea'}
			, {value: 'ER', key: 'Eritrea'}
			, {value: 'EE', key: 'Estonia'}
			, {value: 'ET', key: 'Ethiopia'}
			, {value: 'FK', key: 'Falkland Islands (Malvinas)'}
			, {value: 'FO', key: 'Faroe Islands'}
			, {value: 'FJ', key: 'Fiji'}
			, {value: 'FI', key: 'Finland'}
			, {value: 'FR', key: 'France'}
			, {value: 'GF', key: 'French Guiana'}
			, {value: 'PF', key: 'French Polynesia'}
			, {value: 'TF', key: 'French Southern Territories'}
			, {value: 'GA', key: 'Gabon'}
			, {value: 'GM', key: 'Gambia'}
			, {value: 'GE', key: 'Georgia'}
			, {value: 'DE', key: 'Germany'}
			, {value: 'GH', key: 'Ghana'}
			, {value: 'GI', key: 'Gibraltar'}
			, {value: 'GR', key: 'Greece'}
			, {value: 'GL', key: 'Greenland'}
			, {value: 'GD', key: 'Grenada'}
			, {value: 'GP', key: 'Guadeloupe'}
			, {value: 'GU', key: 'Guam'}
			, {value: 'GT', key: 'Guatemala'}
			, {value: 'GG', key: 'Guernsey'}
			, {value: 'GN', key: 'Guinea'}
			, {value: 'GW', key: 'Guinea-Bissau'}
			, {value: 'GY', key: 'Guyana'}
			, {value: 'HT', key: 'Haiti'}
			, {value: 'HM', key: 'Heard Island and McDonald Islands'}
			, {value: 'VA', key: 'Holy See (Vatican City State)'}
			, {value: 'HN', key: 'Honduras'}
			, {value: 'HK', key: 'Hong Kong'}
			, {value: 'HU', key: 'Hungary'}
			, {value: 'IS', key: 'Iceland'}
			, {value: 'IN', key: 'India'}
			, {value: 'ID', key: 'Indonesia'}
			, {value: 'IR', key: 'Iran'}
			, {value: 'IQ', key: 'Iraq'}
			, {value: 'IE', key: 'Ireland'}
			, {value: 'IM', key: 'Isle of Man'}
			, {value: 'IL', key: 'Israel'}
			, {value: 'IT', key: 'Italy'}
			, {value: 'JM', key: 'Jamaica'}
			, {value: 'JP', key: 'Japan'}
			, {value: 'JE', key: 'Jersey'}
			, {value: 'JO', key: 'Jordan'}
			, {value: 'KZ', key: 'Kazakhstan'}
			, {value: 'KE', key: 'Kenya'}
			, {value: 'KI', key: 'Kiribati'}
			, {value: 'KP', key: 'North Korea'}
			, {value: 'KR', key: 'South Korea'}
			, {value: 'KW', key: 'Kuwait'}
			, {value: 'KG', key: 'Kyrgyzstan'}
			, {value: 'LA', key: 'Lao Republic'}
			, {value: 'LV', key: 'Latvia'}
			, {value: 'LB', key: 'Lebanon'}
			, {value: 'LS', key: 'Lesotho'}
			, {value: 'LR', key: 'Liberia'}
			, {value: 'LY', key: 'Libya'}
			, {value: 'LI', key: 'Liechtenstein'}
			, {value: 'LT', key: 'Lithuania'}
			, {value: 'LU', key: 'Luxembourg'}
			, {value: 'MO', key: 'Macao'}
			, {value: 'MK', key: 'Macedonia'}
			, {value: 'MG', key: 'Madagascar'}
			, {value: 'MW', key: 'Malawi'}
			, {value: 'MY', key: 'Malaysia'}
			, {value: 'MV', key: 'Maldives'}
			, {value: 'ML', key: 'Mali'}
			, {value: 'MT', key: 'Malta'}
			, {value: 'MH', key: 'Marshall Islands'}
			, {value: 'MQ', key: 'Martinique'}
			, {value: 'MR', key: 'Mauritania'}
			, {value: 'MU', key: 'Mauritius'}
			, {value: 'YT', key: 'Mayotte'}
			, {value: 'MX', key: 'Mexico'}
			, {value: 'FM', key: 'Micronesia'}
			, {value: 'MD', key: 'Moldova'}
			, {value: 'MC', key: 'Monaco'}
			, {value: 'MN', key: 'Mongolia'}
			, {value: 'ME', key: 'Montenegro'}
			, {value: 'MS', key: 'Montserrat'}
			, {value: 'MA', key: 'Morocco'}
			, {value: 'MZ', key: 'Mozambique'}
			, {value: 'MM', key: 'Myanmar'}
			, {value: 'NA', key: 'Namibia'}
			, {value: 'NR', key: 'Nauru'}
			, {value: 'NP', key: 'Nepal'}
			, {value: 'NL', key: 'Netherlands'}
			, {value: 'NC', key: 'New Caledonia'}
			, {value: 'NZ', key: 'New Zealand'}
			, {value: 'NI', key: 'Nicaragua'}
			, {value: 'NE', key: 'Niger'}
			, {value: 'NG', key: 'Nigeria'}
			, {value: 'NU', key: 'Niue'}
			, {value: 'NF', key: 'Norfolk Island'}
			, {value: 'MP', key: 'Northern Mariana Islands'}
			, {value: 'NO', key: 'Norway'}
			, {value: 'OM', key: 'Oman'}
			, {value: 'PK', key: 'Pakistan'}
			, {value: 'PW', key: 'Palau'}
			, {value: 'PS', key: 'State of Palestine'}
			, {value: 'PA', key: 'Panama'}
			, {value: 'PG', key: 'Papua New Guinea'}
			, {value: 'PY', key: 'Paraguay'}
			, {value: 'PE', key: 'Peru'}
			, {value: 'PH', key: 'Philippines'}
			, {value: 'PN', key: 'Pitcairn'}
			, {value: 'PL', key: 'Poland'}
			, {value: 'PT', key: 'Portugal'}
			, {value: 'PR', key: 'Puerto Rico'}
			, {value: 'QA', key: 'Qatar'}
			, {value: 'RE', key: 'Réunion'}
			, {value: 'RO', key: 'Romania'}
			, {value: 'RU', key: 'Russian Federation'}
			, {value: 'RW', key: 'Rwanda'}
			, {value: 'BL', key: 'Saint Barthélemy'}
			, {value: 'SH', key: 'Saint Helena'}
			, {value: 'KN', key: 'Saint Kitts and Nevis'}
			, {value: 'LC', key: 'Saint Lucia'}
			, {value: 'MF', key: 'Saint Martin (French part)'}
			, {value: 'PM', key: 'Saint Pierre and Miquelon'}
			, {value: 'VC', key: 'Saint Vincent and the Grenadines'}
			, {value: 'WS', key: 'Samoa'}
			, {value: 'SM', key: 'San Marino'}
			, {value: 'ST', key: 'Sao Tome and Principe'}
			, {value: 'SA', key: 'Saudi Arabia'}
			, {value: 'SN', key: 'Senegal'}
			, {value: 'RS', key: 'Serbia'}
			, {value: 'SC', key: 'Seychelles'}
			, {value: 'SL', key: 'Sierra Leone'}
			, {value: 'SG', key: 'Singapore'}
			, {value: 'SX', key: 'Sint Maarten (Dutch part)'}
			, {value: 'SK', key: 'Slovakia'}
			, {value: 'SI', key: 'Slovenia'}
			, {value: 'SB', key: 'Solomon Islands'}
			, {value: 'SO', key: 'Somalia'}
			, {value: 'ZA', key: 'South Africa'}
			, {value: 'GS', key: 'South Georgia and the South Sandwich Islands'}
			, {value: 'SS', key: 'South Sudan'}
			, {value: 'ES', key: 'Spain'}
			, {value: 'LK', key: 'Sri Lanka'}
			, {value: 'SD', key: 'Sudan'}
			, {value: 'SR', key: 'Suriname'}
			, {value: 'SJ', key: 'Svalbard and Jan Mayen'}
			, {value: 'SZ', key: 'Swaziland'}
			, {value: 'SE', key: 'Sweden'}
			, {value: 'CH', key: 'Switzerland'}
			, {value: 'SY', key: 'Syria'}
			, {value: 'TW', key: 'Taiwan'}
			, {value: 'TJ', key: 'Tajikistan'}
			, {value: 'TZ', key: 'Tanzania'}
			, {value: 'TH', key: 'Thailand'}
			, {value: 'TL', key: 'Timor-Leste'}
			, {value: 'TG', key: 'Togo'}
			, {value: 'TK', key: 'Tokelau'}
			, {value: 'TO', key: 'Tonga'}
			, {value: 'TT', key: 'Trinidad and Tobago'}
			, {value: 'TN', key: 'Tunisia'}
			, {value: 'TR', key: 'Turkey'}
			, {value: 'TM', key: 'Turkmenistan'}
			, {value: 'TC', key: 'Turks and Caicos Islands'}
			, {value: 'TV', key: 'Tuvalu'}
			, {value: 'UG', key: 'Uganda'}
			, {value: 'UA', key: 'Ukraine'}
			, {value: 'AE', key: 'United Arab Emirates'}
			, {value: 'GB', key: 'United Kingdom'}
			, {value: 'US', key: 'United States'}
			, {value: 'UM', key: 'United States Minor Outlying Islands'}
			, {value: 'UY', key: 'Uruguay'}
			, {value: 'UZ', key: 'Uzbekistan'}
			, {value: 'VU', key: 'Vanuatu'}
			, {value: 'VE', key: 'Venezuela'}
			, {value: 'VN', key: 'Vietnam'}
			, {value: 'VG', key: 'Virgin Islands, British'}
			, {value: 'VI', key: 'Virgin Islands, U.S.'}
			, {value: 'WF', key: 'Wallis and Futuna'}
			, {value: 'EH', key: 'Western Sahara'}
			, {value: 'YE', key: 'Yemen'}
			, {value: 'ZM', key: 'Zambia'}
			, {value: 'ZW', key: 'Zimbabwe'}];
	};

	customEncode = (value) => {
		return btoa(unescape(encodeURIComponent(value)));
	}

	customDecode = (value) => {
		try {
			return decodeURIComponent(escape(atob(value)));
		} catch (e) {
			if (this.isBrowser) {
				this.toastr.error('Something went wrong with url, please go back and try again!', 'URL tempering!');
			}
		}
	}


	customEncoding = (stringFormat: string, decode = false) => {
		// program to encode a string to Base64
		// create Base64 Object
		const Base64 = {
			// private property
			_keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
			// public method for encoding
			encode: function (input) {
				let output = '';
				let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
				let i = 0;

				input = Base64._utf8_encode(input);

				while (i < input.length) {

					chr1 = input.charCodeAt(i++);
					chr2 = input.charCodeAt(i++);
					chr3 = input.charCodeAt(i++);

					enc1 = chr1 >> 2;
					enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
					enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
					enc4 = chr3 & 63;

					if (isNaN(chr2)) {
						enc3 = enc4 = 64;
					} else if (isNaN(chr3)) {
						enc4 = 64;
					}

					output = output +
						Base64._keyStr.charAt(enc1) + Base64._keyStr.charAt(enc2) +
						Base64._keyStr.charAt(enc3) + Base64._keyStr.charAt(enc4);

				}

				return output;
			},

// public method for decoding
			decode: function (input) {
				let output = '';
				let chr1, chr2, chr3;
				let enc1, enc2, enc3, enc4;
				let i = 0;

				input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

				while (i < input.length) {
					enc1 = Base64._keyStr.indexOf(input.charAt(i++));
					enc2 = Base64._keyStr.indexOf(input.charAt(i++));
					enc3 = Base64._keyStr.indexOf(input.charAt(i++));
					enc4 = Base64._keyStr.indexOf(input.charAt(i++));
					chr1 = (enc1 << 2) | (enc2 >> 4);
					chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
					chr3 = ((enc3 & 3) << 6) | enc4;
					output = output + String.fromCharCode(chr1);

					if (enc3 != 64 && chr2 != 0) {
						output = output + String.fromCharCode(chr2);
					}
					if (enc4 != 64 && chr3 != 0) {
						output = output + String.fromCharCode(chr3);
					}
				}
				output = Base64._utf8_decode(output);
				return output;
			},

// private method for UTF-8 encoding
			_utf8_encode: function (stringFormat) {
				stringFormat = stringFormat.toString().replace(/\r\n/g, '\n');
				let utftext = '';

				for (let n = 0; n < stringFormat.length; n++) {

					let c = stringFormat.charCodeAt(n);

					if (c < 128) {
						utftext += String.fromCharCode(c);
					} else if ((c > 127) && (c < 2048)) {
						utftext += String.fromCharCode((c >> 6) | 192);
						utftext += String.fromCharCode((c & 63) | 128);
					} else {
						utftext += String.fromCharCode((c >> 12) | 224);
						utftext += String.fromCharCode(((c >> 6) & 63) | 128);
						utftext += String.fromCharCode((c & 63) | 128);
					}

				}

				return utftext;
			},

// private method for UTF-8 decoding
			_utf8_decode: function (utftext) {
				let string = '';
				let i = 0;
				let c, c1, c2, c3;

				while (i < utftext.length) {

					c = utftext.charCodeAt(i);

					if (c < 128) {
						string += String.fromCharCode(c);
						i++;
					} else if ((c > 191) && (c < 224)) {
						c2 = utftext.charCodeAt(i + 1);
						string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
						i += 2;
					} else {
						c2 = utftext.charCodeAt(i + 1);
						c3 = utftext.charCodeAt(i + 2);
						string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
						i += 3;
					}

				}
				return string;
			}
		};
		if (decode) {
			return Base64.decode(stringFormat);
		} else {
			return Base64.encode(stringFormat);
		}
	}

	dateFormat = (dateString = null) => {
		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		if (dateString) {
			const date = new Date(dateString);
			const month = monthNames[date.getMonth()];
			const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
			const year = date.getFullYear();
			return day + '-' + month + '-' + year;
		} else {
			const date = new Date();
			const month = monthNames[date.getMonth()];
			const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
			const year = date.getFullYear();
			return day + '-' + month + '-' + year;
		}
	}

	public static servicename = function (serviceId) {
		let servname = '';
		if (serviceId == '1') {
			servname = 'Question';
		}
		if (serviceId == '2') {
			servname = 'Report';
		}
		if (serviceId == '3') {
			servname = 'Call';
		}
		if (serviceId == '4') {
			servname = 'Chat';
		}
		if (serviceId == '5') {
			servname = 'AstroMall';
		}
		if (serviceId == '6') {
			servname = 'Live Event';
		}
		return servname;
	};

	getUserData(key = null): any {
		const jsonData = this.storage.getItem('userData');
		if (jsonData && jsonData != '') {
			const userData = JSON.parse(jsonData);
			if (key) {
				return (userData && userData[key]) ? userData[key] : '';
			}
			return userData;
		}
		return '';
	}

	loadScript(scriptLink: any, id) {
		let body = this.document.getElementsByTagName('body')[0];
		const dynamicScript = this.document.getElementById(id) as HTMLScriptElement;
		if (dynamicScript) {
			dynamicScript.src = scriptLink;
		} else {
			const script = this.document.createElement('script');
			script.id = id;
			script.type = 'application/javascript';
			script.src = `${scriptLink}`;
			body.appendChild(script);
		}
	}


	loadStyle(styleLink: string, elementName: string) {
		const head = this.document.getElementsByTagName('head')[0];
		let themeLink = this.document.getElementById(elementName) as HTMLLinkElement;
		if (themeLink) {
			themeLink.href = styleLink;
		} else {
			const style = this.document.createElement('link');
			style.id = `${elementName}`;
			style.rel = 'stylesheet';
			style.href = `${styleLink}`;

			head.appendChild(style);
		}
	}

	getPosition(): Promise<any> {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resp => {
				console.log(resp, 'common');
					resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
				},
				err => {
					reject(err);
					console.log(err, 'common error geoloc');
				});
		});
	}

}

